import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Calendar from 'react-calendar';
import MorningIcon from './images/morning-icon.png';
import AfternoonIcon from './images/afternoon-icon.png';
import './phleb-styling-cal.css';

const Index = () => {
  const dateAndTimeText = document.getElementById('date-and-time-pfour-text');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const formatWithLeadingZeros = (number, precision) => {
    const formattedNumber = number.toString().padStart(precision, '0');
    const formattedHour = formattedNumber % 12 || 12;
    return `${formattedHour}`;
  };

  const timeSlots = Array.from({ length: 6 }, (_, index) => formatWithLeadingZeros(6 + index, 2));
  const timeSlotsAfternoon = Array.from({ length: 5 }, (_, index) => formatWithLeadingZeros(12 + index, 2));
  
  const handleDateSelection = (selectedDate) => {
    const currentDate = new Date();
    const oneDayInFuture = new Date(currentDate);
    oneDayInFuture.setDate(currentDate.getDate() + 1);
    
    // Format both dates to compare them correctly
    const formattedSelectedDate = new Date(selectedDate).toDateString();
    const formattedOneDayInFuture = oneDayInFuture.toDateString();
  
    if (formattedSelectedDate === formattedOneDayInFuture) {
      document.getElementById('speedy-pricing-warning').innerText = "* In a hurry? Speedy Sticks offers next day mobile phlebotomy for $250! *";
    } else {
      document.getElementById('speedy-pricing-warning').innerText = ""; // Clear the message if not next day
    }
  
    setSelectedDate(selectedDate);
  };

  const handleTimeSelection = (formattedHour) => {
    setSelectedTime(formattedHour);
  };

  const checkForEnable = () => {
    const nextButtonPageTwo = document.getElementById('next-button-ptwo-a');
    if (selectedDate && selectedTime !== null) {
      nextButtonPageTwo.disabled = false;
      nextButtonPageTwo.style.background = "#F82029";
      const timeAsNumber = parseInt(selectedTime, 10);
      const partOfDay = (timeAsNumber >= 6 && timeAsNumber <= 11) ? "AM" : "PM";
      dateAndTimeText.innerHTML = selectedDate.toDateString() + " at " + selectedTime + ":00" + " " + partOfDay;
    } else {
      nextButtonPageTwo.disabled = true;
      nextButtonPageTwo.style.background = "#F82027C4";
    }
  };

  useEffect(() => {
    checkForEnable();
  }, [selectedDate, selectedTime]);

  return (
    <div className='react-content-container'>
      <p className='choose-date-header'>Choose a date for your home visit.</p>
      <Calendar
        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
        className="REACT-CALENDAR p-2"
        onClickDay={(selectedDate) => handleDateSelection(selectedDate)}
        tileClassName={({ date }) => {
          const isBeforeToday = date < new Date();
          const isToday = date.toDateString() === new Date().toDateString();
          const isMoreThanFourDaysInAdvance = date > new Date(new Date().getTime() + 0 * 24 * 60 * 60 * 1000);

          return (
            (isMoreThanFourDaysInAdvance ? 'custom-class-for-future-days ' : '') +
            (isBeforeToday && !isToday ? 'custom-class-for-days-before-today ' : '') +
            (selectedDate && date.toDateString() === selectedDate.toDateString() ? ' custom-class-for-selected-date' : '')
          );
        }}
        tileContent={({ date, view }) => {
          const isToday = date.toDateString() === new Date().toDateString();
          if (view === 'month') {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {isToday && <div className="today-label"><br />Today</div>}
              </div>
            );
          } else {
            return null;
          }
        }}
      />
      <div>
        <p className="speedy-pricing-text" id="speedy-pricing-warning"></p>
        <p className='choose-time-header'>Choose a time for your home visit.</p>
        <div className='morning-header-sec'>
          <img src={MorningIcon} className='morning-icon' alt='Morning Icon' /><p className='morningtime-text'>Morning time 6-12 AM</p>
        </div>
        <div className="time-slots">
          {timeSlots.map((formattedHour) => (
            <button
              key={formattedHour}
              onClick={(e) => {
                e.preventDefault();
                handleTimeSelection(formattedHour);
              }}
              className={`time-slot-disp ${selectedTime === formattedHour ? 'selected' : ''}`}
            >
              {formattedHour}:00
            </button>
          ))}
        </div>
        {selectedTime && (
          <p>
            {console.log('Selected time:', selectedTime, "AM")}
          </p>
        )}
        <div className='afternoon-header-sec'>
          <img src={AfternoonIcon} className='afternoon-icon' alt='Afternoon Icon'/><p className='afternoontime-text'>Afternoon time 12-4 PM</p>
        </div>
        <div className="time-slots">
          {timeSlotsAfternoon.map((formattedHour) => (
            <button
              key={formattedHour}
              onClick={(e) => {
                e.preventDefault();
                handleTimeSelection(formattedHour);
              }}
              className={`time-slot-disp ${selectedTime === formattedHour ? 'selected' : ''}`}
            >
              {formattedHour}:00
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const rootElement = document.getElementById('part-two-div-container');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(<Index />);
} else {
  console.error('Root element with id "root" not found.');
}